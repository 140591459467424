<template>
    <div class="position_relative">
        <van-image width="100%" class="" :src="bg"></van-image>
        <div class="position_absolute width-100 box">
            <div class="width-90 padding90 white box1 flex-row justify-content-start align-items-star">
                <van-image width="72px" height="72px" fit="cover" round class="margin-r avatar" :src="avatar"></van-image>
                <div class="">
                    <p class="fs-sSig fw-mid">你好！ {{ user.name }}</p>
                    <p class="fs-mid lineHeight">{{ user.phone }}</p>
                    <p v-if="user.c_group" class="label fs-sml textAlign-c">{{ user.c_group }}</p>
                </div>
            </div>
            <div class="bg-white width-90 padding90 box2">
                <!-- 审核信息start -->
                <!--只有审核者才看到-->
                <div v-if="userInfo.identity !== 2" class="width-100 margin-b">
                    <p class="fs-sSig fw-mid black margin-bs">审核信息</p>
                    <div class="flex-row justify-content-spaceBetween align-items-center">
                        <div
                            class="btn btn1 bg-grey br-reg white flex-row justify-content-spaceBetween align-items-center"
                            @click="jumpto('/sh', { pre_filter: 'wait_for_review' })"
                        >
                            <div class="">
                                <p class="fs-sml">
                                    <span class="fs-sSig din">{{ user.unreviewed_count }}</span
                                    >件
                                </p>
                                <p class="fs-sml">待审核</p>
                            </div>
                            <van-image width="30px" height="30px" class="" :src="btn1"></van-image>
                        </div>
                        <div
                            class="btn btn2 bg-grey br-reg white flex-row justify-content-spaceBetween align-items-center"
                            @click="jumpto('/sh', { pre_filter: 'is_reviewed' })"
                        >
                            <div class="">
                                <p class="fs-sml">
                                    <span class="fs-sSig din">{{ user.reviewed_count }}</span
                                    >件
                                </p>
                                <p class="fs-sml">已审核</p>
                            </div>
                            <van-image width="30px" height="30px" class="" :src="btn2"></van-image>
                        </div>
                    </div>
                </div>
                <!--        巡查信息start-->
                <div class="width-100 margin-b">
                    <p class="fs-sSig fw-mid black margin-bs">巡查信息</p>
                    <div
                        @click="jumpto('/sg', { pre_filter: 'waiting' })"
                        class="btn3 bg-grey br-reg white flex-row justify-content-spaceBetween align-items-center"
                    >
                        <div class="">
                            <p class="fs-mid">
                                待巡查工地：<span class="fs-sSig din">{{ user.projects_wait_for_rematch_count }}</span
                                >处
                            </p>
                        </div>
                        <van-image width="30px" height="30px" class="" :src="btn3"></van-image>
                    </div>
                </div>
                <!--        辖区内施工信息start-->
                <div class="width-100 margin-b margin-t">
                    <p class="fs-sSig fw-mid black margin-bs">辖区内施工信息</p>
                    <div class="width-100 flex-row justify-content-spaceBetween align-items-center">
                        <div
                            @click="jumpto('/sg', { pre_filter: 'applying' })"
                            class="width-33 flex-column justify-content-center align-items-center margin-ts"
                        >
                            <van-badge :content="user.project_count.applying" max="99">
                                <img width="30px" height="30px" src="@/assets/image/icon11.png" alt="" class="" />
                            </van-badge>

                            <p class="fs-mid black margin-ts">施工审核中</p>
                        </div>
                        <div
                            @click="jumpto('/sg', { pre_filter: 'building' })"
                            class="width-33 flex-column justify-content-center align-items-center margin-ts"
                        >
                            <van-badge :content="user.project_count.building" max="99">
                                <img width="30px" height="30px" src="@/assets/image/icon10.png" alt="" class="" />
                            </van-badge>

                            <p class="fs-mid black margin-ts">正在施工中</p>
                        </div>
                        <div
                            @click="jumpto('/sg', { pre_filter: 'overtime' })"
                            class="width-33 flex-column justify-content-center align-items-center margin-ts"
                        >
                            <van-badge :content="user.project_count.overtime" max="99">
                                <img width="32px" height="30px" src="@/assets/image/icon12.png" alt="" class="" />
                            </van-badge>

                            <p class="fs-mid black margin-ts">施工已到期</p>
                        </div>
                    </div>
                </div>
                <!--        近期预警信息start-->
                <div class="width-100">
                    <div class="flex-row justify-content-spaceBetween align-items-center margin-b margin-t width-100">
                        <p class="fs-sSig fw-mid black">近期预警施工信息</p>
                        <p class="fs-sml darkgrey" @click="jumpto('/sg', { pre_filter: 'limit' })">更多</p>
                    </div>
                    <!--          列表start-->
                    <div
                        v-for="item in user.limit_projects"
                        @click="jumpto('/detail', { id: item.id })"
                        :key="item.id"
                        class="br-reg van-overflow-hidden bg-white width-100 boxShadow margin-b"
                    >
                        <div class="titBg margin-bs flex-row justify-content-spaceBetween align-items-center">
                            <div class="width-75 flex-row justify-content-start align-items-center">
                                <img width="24px" height="24px" :src="item.icon" alt="" />
                                <p class="fs-big width-80 cutText black fw-mid margin-ls">{{ item.location }}</p>
                            </div>
                            <p class="fs-sml width-20 textAlign-r red">{{ item.step }}</p>
                        </div>
                        <div class="width-90 fs-sml darkgrey paddingLr90 margin-bs flex-row justify-content-spaceBetween align-items-start">
                            <p class="width-40">申请单位：</p>
                            <p class="width-60 textAlign-r black cutText">{{ item.submit_unit }}</p>
                        </div>
                        <div class="width-90 fs-sml darkgrey paddingLr90 margin-bs flex-row justify-content-spaceBetween align-items-start">
                            <p class="">挖掘时间：</p>
                            <p class="textAlign-r black">
                                至{{ dateFormat(item.complete_date) }} <span class="red cutText">{{ item.more }}</span>
                            </p>
                        </div>
                    </div>
                    <!--          列表end-->
                    <van-empty v-if="list.length === 0" description="暂时没有内容哦~" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment'
import dd from 'gdt-jsapi'
export default {
    name: '',
    components: {},
    data() {
        return {
            data: this.$route.query.data,
            bg: require('@/assets/image/banner1.png'),
            btn1: require('@/assets/image/btn1.png'),
            btn2: require('@/assets/image/btn2.png'),
            btn3: require('@/assets/image/btn3.png'),
            list: [
                {
                    index: 0,
                    icon: require('@/assets/image/icon7.png'),
                    street: '安阳街道罗阳大道安阳街道罗阳大道',
                    step: '临近期限',
                    company: '某某施工单位',
                    startTime: '2021年10月11日',
                    endTime: '2021年11月11日',
                    more: '剩余3天'
                },
                {
                    index: 1,
                    icon: require('@/assets/image/icon7.png'),
                    street: '安阳街道罗阳大道安阳街道罗阳大道',
                    step: '临近期限',
                    company: '某某施工单位',
                    startTime: '2021年10月11日',
                    endTime: '2021年11月11日',
                    more: '剩余3天'
                }
            ],
            user: {
                project_count: {
                    applying: 0,
                    buliding: 0,
                    overtime: 0
                }
            },
            avatar: '',

            userInfo: null
        }
    },

    created() {
        this.$store.state.tabbarIndex = this.TAB_INDEX
        this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        if (this.userInfo.dd_user_id === null) {
            this.$router.push('/')
        }
        axios
            .get('/api/dd_user/user_index/')
            .then((res) => {
                this.user = res.data
                for (let project of this.user.limit_projects) {
                    const msOfHour = 60 * 60 * 1000
                    const msOfDay = 24 * msOfHour
                    const now = Math.floor(Date.now() / msOfDay) * msOfDay - 8 * msOfHour // 处理时间偏移
                    const ms = project.complete_date * 1000 - now
                    const day = moment.duration(ms).asDays()
                    if (day < 0) {
                        project.more = `超出${-day}天`
                        project.icon = require('@/assets/image/icon7.png')
                        project.step = '超出期限'
                    } else if (day > 0) {
                        project.more = `剩余${day}天`
                        project.icon = require('@/assets/image/icon6.png')
                        project.step = '临近期限'
                    } else {
                        project.more = '今日到期'
                        project.icon = require('@/assets/image/icon6.png')
                        project.step = '今日到期'
                    }
                }
                sessionStorage.setItem('projectCount', JSON.stringify(res.data.project_count))
                sessionStorage.setItem(
                    'review_counts',
                    JSON.stringify({
                        unreviewed_count: res.data.unreviewed_count,
                        reviewed_count: res.data.reviewed_count
                    })
                )
            })
            .catch((err) => {
                axios.post('/api/log/', {
                    level: 40,
                    content: {
                        context: '/api/dd_user/user_index/',
                        err: err
                    }
                })
                this.$dialog.alert({
                    message: '获取首页信息失败。请检查网络或重新登录。'
                })
            })
        if (process.env.NODE_ENV === 'production') {
            dd.getLoginUser()
                .then((res) => {
                    this.avatar = this.avatarUrl(this.userInfo.access_token, res.avatarMediaId)
                })
                .catch((err) => {
                    axios.post('/api/log/', {
                        level: 40,
                        content: {
                            context: 'dd.getLoginUser',
                            err: err
                        }
                    })
                    this.$dialog.alert({
                        message: '获取用户信息失败。请检查网络或重新登录。'
                    })
                    // console.log(err)
                })
        }
    },
    methods: {}
}
</script>

<style lang="less" scoped>
.box {
    top: 40px;
    margin-bottom: 60px;
}
.label {
    padding: 2px 10px;
    background: linear-gradient(to right, #f66d59, #ffb9af);
    border-radius: 20px;
}
.avatar {
    border: 3px solid #ffffff;
}
.box2 {
    border-radius: 20px 20px 0 0;
}
.btn {
    width: 38%;
    padding: 10px 5%;
}
.btn1 {
    background: linear-gradient(to right, #007aff, #94eafc);
    box-shadow: 0 3px 10px rgba(0, 122, 255, 0.3);
}
.btn2 {
    background: linear-gradient(to right, #4cd964, #cefc94);
    box-shadow: 0 3px 10px rgba(76, 217, 100, 0.3);
}
.btn3 {
    width: 90%;
    padding: 15px 5%;
    background: linear-gradient(to right, #f66d59, #ffb9af);
    box-shadow: 0 3px 10px rgba(246, 109, 89, 0.3);
}
.titBg {
    width: 90%;
    padding: 10px 5%;
    background: linear-gradient(to right, rgba(246, 109, 89, 0.3), rgba(246, 109, 89, 0));
}
</style>
